import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Hint3 = () => (
  <Layout>
    <Header /> 

    <header className="masthead3">
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Hint #3</h1>
          <br></br>
          <br></br>
          <br></br> 
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            Having trouble
            <br></br>
            don't sell yourself short.
            <br></br>
            <br></br>
            Your next clue
            <br></br>
            is next to a court.
            <br></br>
            <br></br>
            But wait, there is a catch,
            <br></br>
            the game is not over. 
            <br></br>
            <br></br>
            Until a voice from the chair
            <br></br>  
            says Game, Set & Match.
          </h2> 
          <h3 className="roll-in-bottom mx-auto">
          Distance:  0.8 miles East</h3>
          <br></br>
          <br></br>
          <a href="/clue3" className="btn btn-primary">
              Back to Clue
            </a>
          <br></br>
          <br></br>
          <br></br> 
          <br></br> 
        </div>
      </div>
    </header>

    {/* <Clues />  

    <SocialLinks />
    <Footer /> */}
    
  </Layout>
);

export default Hint3;
